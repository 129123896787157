import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { RoutingProvider } from "./route";
import { getTheme } from "./utils/theme";
// import "./App.css";

function App() {
  const localeState = useSelector((state) => state.locale.curLocal);
  return (
    <div className="App">
        <IntlProvider locale={localeState.locale}  messages={localeState.messages}>
          <ConfigProvider theme={getTheme()}>
            <RoutingProvider />
          </ConfigProvider>
        </IntlProvider>
    </div>
  );
}

export default App;
